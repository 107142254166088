<template>
  <div class="pies">
    <el-row class="pies-row" v-if="PieForLoop" style="margin-bottom: 0px">
      <el-col :span="5" style="text-align: center; line-height: 40px; height: 40px; font-weight: bold"
        >{{ title }}
      </el-col>
      <!-- <el-col :span="2" style="text-align: left">
        <el-select v-model="showingNum" filterable placeholder="Choose showing Number" style="margin-left: 10px">
          <el-option v-for="item in showingNumOptions" :key="item.value" :label="item.label" :value="item.value">
          </el-option> </el-select
      ></el-col> -->
    </el-row>

    <el-row type="flex" v-if="PieForLoop" style="flex-wrap: wrap" class="pies-chart">
      <el-col
        v-for="(item, index) in getCeil(PieForLoop.length / pieChartGroupNumber)"
        :key="index"
        :span="24 / rowCount"
        class="col"
        style="height: 30vh"
      >
        <Chart
          :chartId="`split_pie_chart_${_uid}_${index}`"
          :option="
            new OverViewPie({
              title: `${index !== 0 ? index * pieChartGroupNumber : 1}-${
                index * pieChartGroupNumber + pieChartGroupNumber
              }`,
              legendTitle: unit,
            }).getOption(['title', 'tooltip', 'series', 'legend', 'color'])
          "
          :sourceData="splitPie(PieForLoop, index * pieChartGroupNumber, PieForLoop.length, unit)"
        ></Chart>
      </el-col>
    </el-row>
    <div class="down-arrow" @click="showMorePie" v-if="sourceData.length > showPieTotal">
      <img src="../assets/images/down_arrow.svg" width="107" height="30" />
    </div>
  </div>
</template>

<script>
// import Chart from '@/submodules/components/Chart'
import { OverViewPie } from '@/templates/chart/pieTemplate'

export default {
  name: 'SplitChart',
  // components: {
  //   Chart,
  // },
  created() {},
  data() {
    return {
      OverViewPie,
      showingNumOptions: [],
      showingNum: 0,
      PieForLoop: [],
      pieChartGroupNumber: 10,
      showPieTotal: 10,
      rowCount: 3,
      colorList: ['#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc'],
    }
  },
  props: {
    sourceData: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: '',
    },
    unit: {
      type: String,
      default: '',
    },
  },
  mounted() {
    this.pieChartGroupNumber = this.colorList.length
    this.showPieTotal = this.rowCount * this.pieChartGroupNumber
    this.pieDataGenerate()
  },
  watch: {
    // showingNum(newV) {
    //   this.PieForLoop = this.sourceData.slice(0, newV)
    // },
    sourceData(newV) {
      // console.debug('newV123', newV)
      this.pieDataGenerate()
    },
  },

  methods: {
    showMorePie() {
      this.showPieTotal += this.rowCount * this.pieChartGroupNumber
      this.PieForLoop = this.sourceData.slice(0, this.showPieTotal)
    },
    pieDataGenerate() {
      console.debug(this.sourceData.length)
      const options = []
      for (
        let index = this.rowCount;
        index < Math.ceil(this.sourceData.length / this.pieChartGroupNumber);
        index += this.rowCount
      ) {
        options.push({
          label: `Top ${index * this.pieChartGroupNumber}`,
          value: index * this.pieChartGroupNumber,
        })
      }
      options.push({
        label: `All`,
        value: this.sourceData.length,
      })
      this.showingNumOptions = options
      this.showingNum = 1 * this.rowCount * this.pieChartGroupNumber
      this.PieForLoop = this.sourceData.slice(0, this.showingNum)
    },
    getCeil(num) {
      return Math.ceil(num)
    },
    splitPie(pie, start, end, unit) {
      const visiableData = pie.slice(start, end)
      const resultData = visiableData.slice(0, this.pieChartGroupNumber)
      const otherData = visiableData.filter(
        (item, index) => index >= this.pieChartGroupNumber && index !== visiableData.length - 1
      )
      if (otherData.length !== 0) {
        const Other = otherData.reduce((a, b) => {
          return {
            name: 'Rest',
            value: a.value + b.value,
            // label: {
            //   show: true,
            //   position: 'inside',
            //   color: '#fff',
            //   fontWeight: 'bold',
            //   fontSize: 20,
            // },
            itemStyle: {
              color: '#808080',
            },
          }
        })
        const cloneOther = Object.assign({}, Other)
        cloneOther.name = `${cloneOther.name}||${cloneOther.value}`
        resultData.push(cloneOther)
      }
      resultData.push({
        name: `||${unit}`,
        itemStyle: { opacity: 0 },
      })
      return resultData
    },
  },
}
</script>

<style lang="scss" scoped>
.col {
  margin-bottom: 40px;
  // background-color: #fff;
}

.pies-row {
  display: flex;
}
.pies {
  border-radius: var(--border-radius-deg);
  padding: 10px;
  margin-bottom: 20px;
  box-shadow: var(--shadow-args);
  // background-image: linear-gradient(45deg, #eefabd, #fdcbcb, #d9f9e4);
  // background-color: #ffffff80;
  background-color: #ffffff;
}

@keyframes move-down {
  0% {
    padding-top: 0px;
  }
  100% {
    padding-top: 100px;
  }
}

@keyframes move-up {
  0% {
    padding-top: 100px;
  }
  100% {
    padding-top: 0px;
  }
}

.down-arrow:hover {
  // animation-name: move-down;
  // animation-duration: 1s;
  // animation-fill-mode: forwards;
  // transform: t;
  padding-top: 20px;
  // transform: translateY(100px);
}

.down-arrow {
  transition: padding-top 0.5s;
  cursor: pointer;
}
</style>
