import {
  Option,
} from '@/templates'
const legendMap = {
  'IP':{
    top:20,
    fontSize:10,
    itemHeight:14,
    itemGap:3,
    itemWidth:25,
    width:150
  },
  'APP':{
    top:20,
    fontSize:10,
    itemHeight:14,
    itemGap:3,
    itemWidth:25,
    width:70
  },
}
export class PieOption extends Option {

  constructor({
    title,
    legendTitle,
    yunit = '',
    ipVersion
  }) {
    super()
    this.title = title
    this.chartType = title.split(' ')[0]
    this.legendTitle = legendTitle
    this.yunit = yunit
    this.ipVersion = ipVersion
    this.clientWidth = document.body.getBoundingClientRect().width
  }

  getToolTip() {
    return {
      formatter: (val) => {
        return val.marker + val.name.split('||')[0] + `<span style="font-weight:bold;margin-left:20px">${handleNumByDot(val.value)}${this.yunit}</span>`;
      }
    }
  }

  getLegend() {
    return [{

      right: 0,
      orient: 'vertical',
      align: 'left',
      data: [`||${this.legendTitle}`],
      formatter: (param => {
        const [name, t1] = param.split('||')
        return [
          `{a|${name}}`,
          `{b|${t1}}`,
        ].join('\t')
      }),
      textStyle: {
        rich: {
          a: {
            align: 'left',
            width: legendMap[this.chartType].width,
            fontWeight: 'bold',
            fontSize: legendMap[this.chartType].fontSize,
          },
          b: {
            align: 'right',
            width: legendMap[this.chartType].width,
            fontWeight: 'bold',
            fontSize: legendMap[this.chartType].fontSize,
          },
        }
      },
    }, {
      id: 'data',
      orient: 'vertical',
      top: legendMap[this.chartType].top,
      right: 0,
      align: 'left',
      type: 'scroll',
      formatter: (param => {
        const [name, t1] = param.split('||')
        if (!name) {
          return
        }
        return [
          `{first|${name}}`,
          `{other|${handleNumByDot(t1)}}`,
        ].join('')
      }),
      itemGap: legendMap[this.chartType].itemGap,
      itemHeight: legendMap[this.chartType].itemHeight,
      itemWidth: legendMap[this.chartType].itemWidth,
      textStyle: {
        rich: {
          first: {
            lineHeight: 10,
            align: 'left',
            width: legendMap[this.chartType].width,
            fontSize: legendMap[this.chartType].fontSize,
          },
          other: {
            align: 'right',
            width: legendMap[this.chartType].width,
            fontSize: legendMap[this.chartType].fontSize,
          },
        }
      },
    }]
  }


  getSeries() {
    return [{
      type: 'pie',
      center: ['20%', '50%'],
      data: [],
      label: {
        show: false,
      },
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: 'rgba(0, 0, 0, 0.5)'
        }
      }
    }]
  }

}

export class OverViewPie extends PieOption {
  getLegend() {
    return [{
        right: 0,
        orient: 'vertical',
        align: 'left',
        data: [`||${this.legendTitle}`],
        formatter: (param => {
          const [name, t1] = param.split('||')
          return [
            `{a|${name}}`,
            `{b|${t1}}`,
          ].join('\t')
        }),
        textStyle: {
          rich: {
            a: {
              align: 'left',
              width: 40,
              fontWeight: 'bold',
              fontSize: this.clientWidth < 1000 ? 5 : 12,
            },
            b: {
              align: 'right',
              width: 40,
              fontWeight: 'bold',
              fontSize: this.clientWidth < 1000 ? 5 : 12,
            },
          }
        },
      },
      {
        orient: 'vertical',
        top: this.clientWidth < 1000 ? 15 : 20,
        right: 0,
        align: 'left',
        type: 'scroll',
        formatter: (param => {
          const [name, t1] = param.split('||')
          if (!name) {
            return
          }
          return [
            `{first|${name}}`,
            `{other|${handleNumByDot(parseInt(t1))}}`,
          ].join('')
        }),
        itemGap: this.clientWidth < 1000 ? 1 : 3,
        itemHeight: this.clientWidth < 1000 ? 7 : 14,
        itemWidth: this.clientWidth < 1000 ? 12 : 25,
        textStyle: {

          rich: {
            first: {
              lineHeight: 10,
              align: 'left',
              width: this.chartType === 'IP' && this.ipVersion == 'v6' ? (this.clientWidth < 1000 ? 40 : 220) : (this.clientWidth < 1000 ? 0 : 100),
              fontSize: this.clientWidth < 1000 ? 5 : 12,
            },
            other: {
              align: 'right',
              width: 70,
              fontSize: this.clientWidth < 1000 ? 5 : 12,
            },
          }
        },
      }
    ]
  }
  getSeries() {
    return [{
      type: 'pie',
      center: ['30%', '50%'],
      data: [],
      label: {
        show: false,
        formatter: function (param) {
          return param.name.split('||')[0]
        }
      },
      itemStyle: {
        borderWidth: 2,
        borderColor: '#fff',
        borderRadius:5,
      },
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          borderWidth: 0,
          shadowOffsetX: 0,
          shadowColor: 'rgba(0, 0, 0, 0.5)'
        }
      }
    }]
  }
}


export class HomePieOption extends PieOption {

  getLegend() {
    const legend = super.getLegend()
    delete legend[1].type
    return legend
  }

}

function handleNumByDot(num) {
  const str = new String(num).toString()
  const numberArray = str.split('.')
  const floatNum = numberArray[1] ? '.' + numberArray[1] : ''
  var LongNum = numberArray[0]
  for (var i = 3; i < LongNum.length; i += 4) {
    LongNum = LongNum.slice(0, LongNum.length - i) + ',' + LongNum.slice(LongNum.length - i)
  }
  return LongNum + floatNum
}